import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import img1 from "../images/mta-blog1.png";


const PortfolioPage = () => (
  <Layout>
    <SEO 
      title="Javier Suzuki | Design & Front-end Development Portfolio - Wordpress Marketing Blog"
      keywords={[`javier`, `suzuki`, `web`, `ui`, `ux`, `design`, `front-end`, `development`, `wordpress`, `blog`]}
    />
    <div className="portfolio-header">
      <h1>Modern Teaching Aids</h1>
      <h5>Marketing Blog</h5>
      <p>Design and development of the marketing blog for Modern Teaching Aids, built with Wordpress and hosted on Amazon Web Services (AWS).</p>
    </div>
    <div className="portfolio-footer">
      <a className="btn btn-primary" href="https://blog.teaching.com.au/" target="_blank" rel="noopener noreferrer">View Project</a>
    </div>
    <div className="portfolio-content">
      <figure className="portfolio-sample">
        {/* <figcaption className="figheading">Design & Development of Marketing Blog</figcaption> */}
        <img src={img1} alt=""/>
      </figure>
    </div>

  </Layout>
)

export default PortfolioPage
